import React, { useState, useEffect } from 'react'
import { motion } from 'framer-motion'
import { CheckCircle, XCircle } from 'react-feather'

import './ToastPrompt.scss'

function ToastPrompt({ toastMessage }) {
  const [show, setShow] = useState(false)
  useEffect(() => {
    if (toastMessage.message !== null) {
      setShow(true)
      setTimeout(() => {
        setShow(false)
      }, 4000)
    }
  }, [toastMessage])
  return (
    <motion.div
      initial={{ opacity: 0, y: -100 }}
      animate={show ? { opacity: 1, y: 0 } : { opacity: 0, y: -100 }}
      className='toast-prompt'
    >
      <div className='toast-prompt__prompt'>
        {toastMessage.error ? <XCircle size={21} /> : <CheckCircle size={21} />}
        <p>{toastMessage.message}</p>
      </div>
    </motion.div>
  )
}

export default ToastPrompt
