import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'

import './ProgressBar.scss'

function ProgressBar({ isVisible, step, steps }) {
  const progressPercentage = () => {
    return `${(step / steps) * 100}%`
  }

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          className='progress-bar'
          initial={{ y: -10 }}
          animate={{ y: 0 }}
          exit={{ y: -10 }}
          transition={{
            type: 'spring',
            damping: 40,
            stiffness: 400,
          }}
        >
          <div
            style={{ width: progressPercentage() }}
            className='porgress-bar__progress'
          ></div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default ProgressBar
