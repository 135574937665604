import React, { useEffect, useState, useContext } from 'react'
import { motion } from 'framer-motion'
import { ArrowRight, Check } from 'react-feather'

import AuthContext from '../../../context/context'

import './EditLink.scss'

const variants = {
  hidden: {
    y: 30,
    opacity: 0,
  },
  show: {
    y: 0,
    opacity: 1,
  },
}

function EditLink({ text, step, state }) {
  const context = useContext(AuthContext)

  // active, done, todo

  const [active, setActive] = useState(false)
  const [arrow, setArrow] = useState(false)
  const [check, setCheck] = useState(false)

  useEffect(() => {
    if (step === state) {
      setActive(true)
      setArrow(true)
      setCheck(false)
    } else if (step < state) {
      setArrow(false)
      setCheck(true)
      setActive(true)
    } else {
      setArrow(false)
      setCheck(false)
      setActive(false)
    }
  }, [step, state])

  const handleClick = () => {
    if (step < state) {
      context.setState(step)
    }
  }

  return (
    <motion.button
      variants={variants}
      disabled={state === step}
      className={`edit-link edit-link--${active ? 'active' : 'disabled'}`}
      onClick={() => handleClick()}
    >
      <div
        className={`edit-link__arrow edit-link__arrow--${
          arrow && 'active'
        } edit-link__arrow--${check && 'hidden'} edit-link__arrow--${
          !arrow && 'hidden'
        }`}
      >
        <ArrowRight />
      </div>
      <div
        className={`edit-link__check edit-link__check--${
          check && 'active'
        }  edit-link__check--${arrow && 'hidden'}`}
      >
        <Check />
      </div>
      <p>{text}</p>
    </motion.button>
  )
}

export default EditLink
