import React from 'react'

import './Spinner.scss'

function Spinner({ color, width }) {
  return (
    <React.Fragment>
      <div style={{ width: width, height: width }} className='spinner'>
        <div className={`spinner__dot spinner__dot--${color}`}></div>
        <div className={`spinner__dot spinner__dot--${color}`}></div>
        <div className={`spinner__dot spinner__dot--${color}`}></div>
        <div className={`spinner__dot spinner__dot--${color}`}></div>
        <div className={`spinner__dot spinner__dot--${color}`}></div>
        <div className={`spinner__dot spinner__dot--${color}`}></div>
      </div>
    </React.Fragment>
  )
}

export default Spinner
