import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { NavLink } from 'react-router-dom'
import { Clipboard, Aperture, User, MessageCircle } from 'react-feather'

import './MobileUserNavigation.scss'

function MobileUserNavigation({ isVisible, orders }) {
  return (
    <AnimatePresence>
      {isVisible && (
        <motion.nav
          className='mobile-user-navigation'
          initial={{ y: 100 }}
          animate={{ y: 0 }}
          exit={{ y: 100 }}
          transition={{
            type: 'spring',
            damping: 40,
            stiffness: 400,
          }}
        >
          <div className='mobile-user-navigation__link-container'>
            <NavLink
              className={`mobile-user-navigation__link mobile-user-navigation__link--${
                orders > 0 && 'notification'
              }`}
              data-before-content={orders}
              to='/auftraege'
            >
              <div>
                <Clipboard />
              </div>
              <div>Aufträge</div>
            </NavLink>
            <NavLink className='mobile-user-navigation__link' to='/produkte'>
              <div>
                <Aperture />
              </div>
              <div>Produkte</div>
            </NavLink>
            <NavLink className='mobile-user-navigation__link' to='/profil'>
              <div>
                <User />
              </div>
              <div>Profil</div>
            </NavLink>
            <NavLink className='mobile-user-navigation__link' to='/kontakt'>
              <div>
                <MessageCircle />
              </div>
              <div>Kontakt</div>
            </NavLink>
          </div>
        </motion.nav>
      )}
    </AnimatePresence>
  )
}

export default MobileUserNavigation
