const refreshSession = () => {
  const requestBody = {
    query: `
      query {
        refreshSession {
          exp
          isAdmin
        }                
      }
    `,
  }

  return fetch('/graphql', {
    method: 'POST',
    body: JSON.stringify(requestBody),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((res) => {
      if (res.status !== 200 && res.status !== 201) {
        throw new Error('Failed!')
      }
      return res.json()
    })
    .then((resData) => {
      return {
        exp: resData.data.refreshSession.exp,
        isAdmin: resData.data.refreshSession.isAdmin,
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

const checkExp = async (context) => {
  if (localStorage.getItem('exp') < Date.now()) {
    const result = await refreshSession()
    if (result.isAdmin == null) {
      context.logout()
      return false
    } else {
      context.login(result.exp, result.isAdmin)
      return true
    }
  }
  return true
}

export default checkExp
