import React from 'react'
import { motion } from 'framer-motion'

import './Logo.scss'

function Logo({ height }) {
  const strokeOneVariants = {
    start: { pathLength: 0, pathOffset: 0.6, pathSpacing: 0.2 },
    end: { pathLength: 1, pathOffset: 0, pathSpacing: 0.3 },
  }
  const strokeTwoVariants = {
    start: {
      pathLength: 0,
      pathOffset: 0.6,
      pathSpacing: 0.2,
    },
    end: { pathLength: 1, pathOffset: 0, pathSpacing: 0.3 },
  }

  return (
    <svg
      className='logo'
      xmlns='http://www.w3.org/2000/svg'
      height={height}
      width='100%'
      viewBox='183 0 51 135'
    >
      <motion.path
        d='M387.718.001c-5.676 0-11.293 2.508-15.078 7.312l-58.646 74.471L255.35 7.313A19.18 19.18 0 0 0 240.287.001h0c-5.878 0-11.431 2.693-15.062 7.312l-58.641 74.469-58.642-74.468C104.307 2.697 98.754.002 92.88.002a19.16 19.16 0 0 0-15.064 7.312L4.112 100.917c-6.554 8.317-5.119 20.375 3.2 26.927a19.18 19.18 0 0 0 26.929-3.202l58.641-74.469 58.638 74.468c3.635 4.617 9.188 7.312 15.064 7.312a19.16 19.16 0 0 0 15.062-7.312l58.644-74.469 58.644 74.469c3.637 4.617 9.188 7.312 15.062 7.312s11.432-2.693 15.063-7.312l73.709-93.6a19.18 19.18 0 0 0-3.201-26.93c-3.513-2.767-7.699-4.11-11.849-4.11z'
        fill='transparent'
        strokeWidth='3'
        stroke='#f29400'
        strokeLinecap='round'
        initial='start'
        animate='end'
        transform='translate(2,2)'
        variants={strokeOneVariants}
        transition={{
          duration: 2,
        }}
      />
      <motion.path
        d='M387.718.001c-5.676 0-11.293 2.508-15.078 7.312l-58.646 74.471L255.35 7.313A19.18 19.18 0 0 0 240.287.001h0c-5.878 0-11.431 2.693-15.062 7.312l-58.641 74.469-58.642-74.468C104.307 2.697 98.754.002 92.88.002a19.16 19.16 0 0 0-15.064 7.312L4.112 100.917c-6.554 8.317-5.119 20.375 3.2 26.927a19.18 19.18 0 0 0 26.929-3.202l58.641-74.469 58.638 74.468c3.635 4.617 9.188 7.312 15.064 7.312a19.16 19.16 0 0 0 15.062-7.312l58.644-74.469 58.644 74.469c3.637 4.617 9.188 7.312 15.062 7.312s11.432-2.693 15.063-7.312l73.709-93.6a19.18 19.18 0 0 0-3.201-26.93c-3.513-2.767-7.699-4.11-11.849-4.11z'
        transform='translate(2,2)'
        fill='#f29400'
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 1.5, duration: 1 }}
      />
      <motion.path
        d='M70.089 109.201c0-12.563 10.186-22.75 22.75-22.75s22.75 10.187 22.75 22.75-10.188 22.75-22.75 22.75-22.75-10.185-22.75-22.75'
        transform='translate(2,2)'
        fill='#b0a79d'
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 1.5, duration: 1 }}
      />
      <motion.path
        d='M70.089 109.201c0-12.563 10.186-22.75 22.75-22.75s22.75 10.187 22.75 22.75-10.188 22.75-22.75 22.75-22.75-10.185-22.75-22.75'
        fill='transparent'
        strokeWidth='3'
        stroke='#b0a79d'
        strokeLinecap='round'
        initial='start'
        transform='translate(2,2)'
        animate='end'
        variants={strokeTwoVariants}
        transition={{ duration: 2 }}
      />
    </svg>
  )
}

export default Logo
