import React from 'react'

export default React.createContext({
  exp: null,
  isAdmin: null,
  state: 1,
  order: null,
  product: null,
  setToastMessage: () => {},
  setHideNav: () => {},
  setOrder: () => {},
  setUser: () => {},
  setProduct: () => {},
  setState: () => {},
  login: () => {},
  logout: () => {},
  setView: () => {},
})
