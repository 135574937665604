import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'

import Logo from '../../atoms/Logo/Logo'

import CategoryLogo from '../../atoms/CategoryLogo/CategoryLogo'
import MotionNavLink from '../../atoms/MotionNavLink/MotionNavLink'
import EditLink from '../../atoms/EditLink/EditLink'
import NavBack from '../../atoms/NavBack/NavBack'

import './DesktopNavigation.scss'

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      delayChildren: 0.2,
      staggerChildren: 0.2,
    },
  },
}

function DesktopNavigation({
  type,
  state,
  order,
  user,
  product,
  orderNotifications,
  productNotifications,
}) {
  const content = {
    login: [
      false,
      <Logo height={50} className='desktop-navigation__logo' />,
      [
        ['Log In', '/login', 0],
        ['Registrieren', '/registrieren', 0],
      ],
      [false, 'Kontakt'],
    ],
    homeUser: [
      false,
      <Logo height={50} className='desktop-navigation__logo' />,
      [
        ['Aufträge', '/auftraege', orderNotifications],
        ['Produkte', '/produkte', 0],
        ['Profil', '/profil', 0],
        ['Kontakt', '/kontakt', 0],
      ],
      ['logout', 'Log Out'],
    ],
    homeAdmin: [
      false,
      <Logo height={50} className='desktop-navigation__logo' />,
      [
        ['Kalender', '/kalender', 0],
        ['Aufträge', '/auftraege', orderNotifications],
        ['Produkte', '/produkte', productNotifications],
        ['Benutzer:innen', '/benutzerinnen', 0],
        ['Einstellungen', '/einstellungen', 0],
      ],
      ['logout', 'Log Out'],
    ],
    archive: [
      false,
      <CategoryLogo
        type='order'
        headline='Auftragsarchiv'
        className='desktop-navigation__logo'
      />,
      [],
      ['back', 'Zurück'],
    ],
    addUserOrder: [
      true,
      <CategoryLogo
        type='order'
        headline='Anfrage erstellen'
        className='desktop-navigation__logo'
      />,
      ['Zeitraum wählen', 'Informationen', 'Produktauswahl', 'Überprüfen'],
      ['back', 'Auftrag abbrechen'],
    ],
    addAdminOrder: [
      true,
      <CategoryLogo
        type='order'
        headline='Auftrag erstellen'
        className='desktop-navigation__logo'
      />,
      [
        'Zeitraum wählen',
        'Informationen',
        'User auswählen',
        'Produktauswahl',
        'Überprüfen',
      ],
      ['back', 'Auftrag abbrechen'],
    ],
    distributeOrder: [
      true,
      <CategoryLogo
        type='order'
        headline={order && order.name}
        name={order && `${order.user.firstName} ${order.user.lastName}`}
        date={
          order &&
          `${order.start.format('DD.MM.YYYY')} - ${order.end.format(
            'DD.MM.YYYY'
          )}`
        }
        hidden={!order && true}
        className='desktop-navigation__logo'
      />,
      ['Übersicht', 'Haftungsübernahme'],
      ['back', 'Ausgabe abbrechen'],
    ],
    returnOrder: [
      true,
      <CategoryLogo
        type='order'
        headline={order && order.name}
        name={order && `${order.user.firstName} ${order.user.lastName}`}
        date={
          order &&
          `${order.start.format('DD.MM.YYYY')} - ${order.end.format(
            'DD.MM.YYYY'
          )}`
        }
        hidden={!order && true}
        className='desktop-navigation__logo'
      />,
      ['Übersicht'],
      ['back', 'Retour abbrechen'],
    ],
    order: [
      false,
      <CategoryLogo
        type='order'
        headline={order && order.name}
        name={order && `${order.user.firstName} ${order.user.lastName}`}
        date={
          order &&
          `${order.start.format('DD.MM.YYYY')} - ${order.end.format(
            'DD.MM.YYYY'
          )}`
        }
        hidden={!order && true}
        className='desktop-navigation__logo'
      />,
      [],
      ['back', 'Zurück'],
    ],
    editOrder: [
      true,
      <CategoryLogo
        type='order'
        headline={order && order.name}
        name={order && `${order.user.firstName} ${order.user.lastName}`}
        date={
          order &&
          `${order.start.format('DD.MM.YYYY')} - ${order.end.format(
            'DD.MM.YYYY'
          )}`
        }
        hidden={!order && true}
        className='desktop-navigation__logo'
      />,
      ['Zeitraum', 'Informationen', 'User', 'Produktauswahl', 'Überprüfen'],
      ['back', 'Abbrechen'],
    ],
    productCheckOverview: [
      false,
      <CategoryLogo
        type='checkUp'
        headline={product && `${product.brand.name} ${product.name}`}
        name={product && `Set ${product.set}`}
        date={product && product.category.name}
        hidden={!product && true}
        className='desktop-navigation__logo'
      />,
      [
        [
          'Produktcheck durchführen',
          `/produktcheck-erstellen/${product && product._id}`,
          0,
        ],
        [
          'Verlauf ansehen',
          `/produktcheck-verlauf/${product && product._id}`,
          0,
        ],
      ],
      ['back', 'Zurück'],
    ],
    productCheckHistory: [
      false,
      <CategoryLogo
        type='checkUp'
        headline={product && `${product.brand.name} ${product.name}`}
        name={product && `Set ${product.set}`}
        date={product && product.category.name}
        hidden={!product && true}
        className='desktop-navigation__logo'
      />,
      [],
      ['back', 'Zurück'],
    ],
    addProductCheck: [
      true,
      <CategoryLogo
        type='checkUp'
        headline={product && `${product.brand.name} ${product.name}`}
        name={product && `Set ${product.set}`}
        date={product && product.category.name}
        hidden={!product && true}
        className='desktop-navigation__logo'
      />,
      ['Ergebnis', 'Verwendung', 'Nächster CheckUp'],
      ['back', 'CheckUp abbrechen'],
    ],
    history: [
      false,
      <CategoryLogo
        type='history'
        headline='Produktcheck-Verlauf'
        name='a398'
        brand='Canon'
        set='3'
        className='desktop-navigation__logo'
      />,
      [],
      ['back', 'Zurück'],
    ],
    addProduct: [
      true,
      <CategoryLogo
        type='product'
        headline='Produkterstellung'
        className='desktop-navigation__logo'
      />,
      [
        'Informationen',
        'Filterkriterien',
        'Produktbild',
        'Anleitung',
        'CheckUp-Datum',
        'Überprüfen',
      ],

      ['back', 'Zurück'],
    ],
    editProduct: [
      true,
      <CategoryLogo
        type='product'
        headline={product && `${product.brand.name} ${product.name}`}
        name={product && `Set ${product.set}`}
        date={product && product.category.name}
        hidden={!product && true}
        className='desktop-navigation__logo'
      />,
      [
        'Informationen',
        'Filterkriterien',
        'Produktbild',
        'Anleitung',
        'CheckUp-Datum',
        'Überprüfen',
      ],

      ['back', 'Zurück'],
    ],
    product: [
      false,
      <CategoryLogo
        type='product'
        headline={product && `${product.brand.name} ${product.name}`}
        name={product && `Set ${product.set}`}
        date={product && product.category.name}
        hidden={!product && true}
        className='desktop-navigation__logo'
      />,
      [],
      ['back', 'Zurück'],
    ],
    userOverview: [
      false,
      <CategoryLogo
        type='user'
        headline='Auftragsverlauf'
        date={user && `${user.firstName} ${user.lastName}`}
        className='desktop-navigation__logo'
      />,
      [],
      ['back', 'Zurück'],
    ],
    editUser: [
      false,
      <CategoryLogo
        type='user'
        headline='Benutzer:in bearbeiten'
        date={user && `${user.firstName} ${user.lastName}`}
        className='desktop-navigation__logo'
      />,
      [],
      ['back', 'Zurück'],
    ],
  }

  const getContent = (type) => {
    if (content[type][0] === false) {
      return (
        <motion.div
          variants={container}
          initial='hidden'
          animate='show'
          className='desktop-navigation__link-container'
          key={'1'}
        >
          {content[type][2].map((e, i) => (
            <MotionNavLink
              text={e[0]}
              url={e[1]}
              notifications={e[2]}
              key={i}
            />
          ))}

          <NavBack prompt={content[type][3][0]} text={content[type][3][1]} />
        </motion.div>
      )
    } else {
      return (
        <motion.div
          variants={container}
          initial='hidden'
          animate='show'
          className='desktop-navigation__link-container'
          key={'2'}
        >
          {content[type][2].map((e, i) => (
            <EditLink key={i} text={e} step={i + 1} state={state} />
          ))}
          <NavBack prompt={content[type][3][0]} text={content[type][3][1]} />
        </motion.div>
      )
    }
  }
  return (
    <AnimatePresence>
      <nav className='desktop-navigation'>
        <div className='desktop-navigation__logo--container'>
          {content[type][1]}
        </div>
        {getContent(type)}
      </nav>
    </AnimatePresence>
  )
}

export default DesktopNavigation
