import React, { useContext, useState, useEffect } from 'react'

import DesktopNavigation from '../../molecules/DesktopNavigation/DesktopNavigation'
import MobileNavigation from '../../molecules/MobileNavigation/MobileNavigation'
import ProgressBar from '../../atoms/ProgressBar/ProgressBar'

import { notifications } from '../../../API/notifications'

import AuthContext from '../../../context/context'

import './Navigation.scss'

const adminNavigationObject = {
  addOrder: ['addAdminOrder', true, 6],
  orderOverview: ['order', false, 0],
  editOrder: ['editOrder', true, 6],
  distributeOrder: ['distributeOrder', true, 3],
  returnOrder: ['returnOrder', true, 2],
  userOverview: ['userOverview', false, 0],
  editUser: ['editUser', false, 0],
  addProduct: ['addProduct', true, 7],
  productOverview: ['product', false, 0],
  editProduct: ['editProduct', true, 7],
  productCheckOverview: ['productCheckOverview', false, 0],
  addProductCheck: ['addProductCheck', true, 4],
  productCheckHistory: ['productCheckHistory', false, 0],
  archive: ['archive', false, 0],
}

function Navigation({ isAdmin, hideNav, state, order, view, user, product }) {
  const [orderNotifications, setOrderNotifications] = useState(0)
  const [productNotifications, setProductNotifications] = useState(0)
  const [mounted, setMounted] = useState(true)

  useEffect(() => {
    return () => {
      setMounted(false)
    }
  }, [])

  useEffect(() => {
    if (isAdmin === true || isAdmin === false) {
      getNotifications()
    }
  }, [isAdmin])

  useEffect(() => {
    if (view === null && isAdmin) {
      getNotifications()
    }
  }, [view])

  const context = useContext(AuthContext)

  const adminNavigationType = () => {
    if (view) {
      return adminNavigationObject[view]
    }
    return ['homeAdmin', false, 0]
  }

  const getNotifications = async () => {
    const response = await notifications(context)

    if (response && mounted) {
      if (response.data) {
        setOrderNotifications(response.data.notifications.orders)
        setProductNotifications(response.data.notifications.products)
      }
    }
  }

  const getUserView = () => {
    if (view === 'addOrder') {
      return 'addUserOrder'
    }
    if (view === 'archive') {
      return 'archive'
    }

    return 'homeUser'
  }

  return (
    <div className='navigation'>
      {isAdmin === null && (
        <div className='navigation__desktop'>
          <DesktopNavigation type='login' />
        </div>
      )}
      {isAdmin === false && (
        <React.Fragment>
          <div className='navigation__desktop'>
            <DesktopNavigation
              orderNotifications={orderNotifications}
              productNotifications={productNotifications}
              type={getUserView()}
              state={state}
            />
          </div>

          <div className='navigation__mobile'>
            <ProgressBar
              isVisible={view === 'addOrder'}
              step={state}
              steps={5}
            />
            <MobileNavigation
              isVisible={view !== 'addOrder' && !hideNav}
              isAdmin={false}
              productNotifications={productNotifications}
              orderNotifications={orderNotifications}
            />
          </div>
        </React.Fragment>
      )}

      {isAdmin === true && (
        <React.Fragment>
          <div className='navigation__desktop'>
            <DesktopNavigation
              type={adminNavigationType()[0]}
              state={state}
              order={order}
              user={user}
              product={product}
              orderNotifications={orderNotifications}
              productNotifications={productNotifications}
            />
          </div>
          <div className='navigation__mobile'>
            <ProgressBar
              isVisible={adminNavigationType()[1]}
              step={state}
              steps={adminNavigationType()[2]}
            />
            <MobileNavigation
              isVisible={adminNavigationType()[0] === 'homeAdmin' && !hideNav}
              isAdmin={true}
              productNotifications={productNotifications}
              orderNotifications={orderNotifications}
            />
          </div>
        </React.Fragment>
      )}
    </div>
  )
}

export default Navigation
