import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { NavLink } from 'react-router-dom'
import { Calendar, Clipboard, Aperture, Users, Sliders } from 'react-feather'

import './MobileAdminNavigation.scss'

function MobileAdminNavigation({ isVisible, orders, checkUps }) {
  return (
    <AnimatePresence>
      {isVisible && (
        <motion.nav
          className='mobile-admin-navigation'
          initial={{ y: 100 }}
          animate={{ y: 0 }}
          exit={{ y: 100 }}
          transition={{
            type: 'spring',
            damping: 40,
            stiffness: 400,
          }}
        >
          <div className='mobile-admin-navigation__link-container'>
            <NavLink className='mobile-admin-navigation__link' to='/kalender'>
              <div>
                <Calendar size={27} />
              </div>
            </NavLink>
            <NavLink
              className={`mobile-admin-navigation__link mobile-admin-navigation__link--${
                orders > 0 && 'notification'
              }`}
              data-before-content={orders}
              to='/auftraege'
            >
              <div>
                <Clipboard size={27} />
              </div>
            </NavLink>
            <NavLink
              className={`mobile-admin-navigation__link mobile-admin-navigation__link--${
                checkUps > 0 && 'notification'
              }`}
              data-before-content={checkUps}
              to='/produkte'
            >
              <div>
                <Aperture size={27} />
              </div>
            </NavLink>
            <NavLink
              className='mobile-admin-navigation__link'
              to='/benutzerinnen'
            >
              <div>
                <Users size={27} />
              </div>
            </NavLink>
            <NavLink
              className='mobile-admin-navigation__link'
              to='/einstellungen'
            >
              <div>
                <Sliders size={27} />
              </div>
            </NavLink>
          </div>
        </motion.nav>
      )}
    </AnimatePresence>
  )
}

export default MobileAdminNavigation
