import React from 'react'
import { NavLink } from 'react-router-dom'
import { motion } from 'framer-motion'
import {
  Clipboard,
  Aperture,
  User,
  MessageCircle,
  LogIn,
  UserPlus,
  Users,
  Calendar,
  Sliders,
  Info,
  File,
  CheckCircle,
  Rewind,
} from 'react-feather'

import './MotionNavLink.scss'

const MotionLink = motion(NavLink)

const variants = {
  hidden: {
    y: 30,
    opacity: 0,
  },
  show: {
    y: 0,
    opacity: 1,
  },
}

const content = {
  Aufträge: <Clipboard />,
  Produkte: <Aperture />,
  Profil: <User />,
  Kontakt: <MessageCircle />,
  'Log In': <LogIn />,
  Registrieren: <UserPlus />,
  'Benutzer:innen': <Users />,
  Kalender: <Calendar />,
  Einstellungen: <Sliders />,
  'Informationen bearbeiten': <Info />,
  'Dateien bearbeiten': <File />,
  'Produktcheck durchführen': <CheckCircle />,
  'Verlauf ansehen': <Rewind />,
}

function MotionNavLink({ text, url, notifications }) {
  return (
    <MotionLink variants={variants} className='motion-nav-link' to={url}>
      <div
        data-before-content={notifications}
        className={`motion-nav-link__icon--${
          notifications && 'notifications'
        } `}
      >
        {content[text]}
      </div>
      <p>{text}</p>
    </MotionLink>
  )
}

export default MotionNavLink
