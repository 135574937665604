import React from 'react'
import MobileAdminNavigation from '../MobileAdminNavigation/MobileAdminNavigation'
import MobileUserNavigation from '../MobileUserNavigation/MobileUserNavigation'

import './MobileNavigation.scss'

function MobileNavigation({
  isVisible,
  isAdmin,
  orderNotifications,
  productNotifications,
}) {
  return (
    <React.Fragment>
      {isAdmin ? (
        <MobileAdminNavigation
          isVisible={isVisible}
          orders={orderNotifications}
          checkUps={productNotifications}
        />
      ) : (
        <MobileUserNavigation
          isVisible={isVisible}
          orders={orderNotifications}
        />
      )}
    </React.Fragment>
  )
}

export default MobileNavigation
