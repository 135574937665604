import React, { useState, useEffect, Suspense, lazy } from 'react'
import { BrowserRouter } from 'react-router-dom'

import AuthContext from './context/context'

import { logoutCookies } from './API/auth'

import Navigation from './components/organisms/Navigation/Navigation'
import ToastPrompt from './components/atoms/ToastPrompt/ToastPrompt'
import Spinner from './components/atoms/Spinner/Spinner'

const Authentication = lazy(() => import('./routes/Authentication'))
const User = lazy(() => import('./routes/User'))
const Admin = lazy(() => import('./routes/Admin'))

function App() {
  const [exp, setExp] = useState(null)
  const [isAdmin, setIsAdmin] = useState(null)
  const [toastMessage, setToastMessage] = useState({ message: null })
  const [order, setOrder] = useState(null)
  const [user, setUser] = useState(null)
  const [product, setProduct] = useState(null)
  const [state, setState] = useState(1)
  const [hideNav, setHideNav] = useState(false)
  const [done, setDone] = useState(false)
  const [view, setView] = useState(null)

  useEffect(() => {
    setDone(false)
    if (localStorage.getItem('exp') && localStorage.getItem('isAdmin')) {
      localStorage.isAdmin === 'true' ? setIsAdmin(true) : setIsAdmin(false)
      setExp(localStorage.getItem('exp'))
    }
    setDone(true)
  }, [])

  const login = (exp, isAdmin) => {
    localStorage.setItem('isAdmin', isAdmin)
    localStorage.setItem('exp', exp)
    setIsAdmin(isAdmin)
    setExp(exp)
  }

  const logout = async () => {
    await logoutCookies()
    setExp(null)
    setIsAdmin(null)
    localStorage.clear()
  }

  return (
    <BrowserRouter>
      <AuthContext.Provider
        value={{
          exp: exp,
          isAdmin: isAdmin,
          state: state,
          order: order,
          product: product,
          login: login,
          logout: logout,
          setToastMessage: setToastMessage,
          setHideNav: setHideNav,
          setOrder: setOrder,
          setUser: setUser,
          setProduct: setProduct,
          setState: setState,
          setView: setView,
        }}
      >
        <ToastPrompt toastMessage={toastMessage} />
        <Navigation
          isAdmin={isAdmin}
          hideNav={hideNav}
          order={order}
          user={user}
          product={product}
          state={state}
          view={view}
        />
        <Suspense
          fallback={
            <div className='lazy-spinner'>
              <Spinner color='orange' width={40} />
            </div>
          }
        >
          {!exp && done && <Authentication />}
          {exp && !isAdmin && done && <User />}
          {exp && isAdmin && done && <Admin />}
        </Suspense>
      </AuthContext.Provider>
    </BrowserRouter>
  )
}

export default App
