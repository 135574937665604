import refreshSession from './refreshSession'
import fetchItems from './fetchItems'

const notifications = async (context) => {
  const allowed = await refreshSession(context)
  if (allowed === false) {
    return
  }

  const requestBody = {
    query: `
      query {
        notifications {
          orders
          products
        }
      }
    `,
  }

  return fetchItems(requestBody)
}

export { notifications }
