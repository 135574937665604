import React from 'react'
import { motion } from 'framer-motion'

import './CategoryLogo.scss'

function CategoryLogo({ headline, name, brand, set, type, date, hidden }) {
  const strokeOneVariants = {
    start: { pathLength: 0, pathOffset: 1, pathSpacing: 0.5 },
    end: { pathLength: 1, pathOffset: 0, pathSpacing: 0.3 },
  }
  const strokeTwoVariants = {
    start: {
      pathLength: 0,
      pathOffset: 1,
      pathSpacing: 0.5,
    },
    end: { pathLength: 1, pathOffset: 0, pathSpacing: 0.3 },
  }

  const content = {
    order: {
      viewBox: '0 14 23 22',
      paths: [
        'M16,17L18,17C19.097,17 20,17.903 20,19L20,33C20,34.097 19.097,35 18,35L6,35C4.903,35 4,34.097 4,33L4,19C4,17.903 4.903,17 6,17L8,17',
        'M16,16C16,15.448 15.552,15 15,15L9,15C8.448,15 8,15.448 8,16L8,18C8,18.552 8.448,19 9,19L15,19C15.552,19 16,18.552 16,18L16,16Z',
      ],
      strokeWidth: '0.7',
    },
    settings: {
      viewBox: '30 2 35 95',
      paths: [
        'M80.833,62.5C79.701,65.066 80.247,68.078 82.208,70.083L82.458,70.333C84.023,71.896 84.902,74.018 84.902,76.229C84.902,78.44 84.023,80.563 82.458,82.125C80.896,83.689 78.773,84.569 76.562,84.569C74.352,84.569 72.229,83.689 70.667,82.125L70.417,81.875C68.412,79.914 65.399,79.368 62.833,80.5C60.32,81.577 58.678,84.057 58.667,86.792L58.667,87.5C58.667,92.072 54.905,95.833 50.333,95.833C45.762,95.833 42,92.072 42,87.5L42,87.125C41.934,84.309 40.144,81.806 37.5,80.833C34.934,79.701 31.922,80.247 29.917,82.208L29.667,82.458C28.104,84.023 25.982,84.902 23.771,84.902C21.56,84.902 19.437,84.023 17.875,82.458C16.311,80.896 15.431,78.773 15.431,76.562C15.431,74.352 16.311,72.229 17.875,70.667L18.125,70.417C20.086,68.412 20.632,65.399 19.5,62.833C18.423,60.32 15.943,58.678 13.208,58.667L12.5,58.667C7.928,58.667 4.167,54.905 4.167,50.333C4.167,45.762 7.928,42 12.5,42L12.875,42C15.691,41.934 18.194,40.144 19.167,37.5C20.299,34.934 19.753,31.922 17.792,29.917L17.542,29.667C15.977,28.104 15.098,25.982 15.098,23.771C15.098,21.56 15.977,19.437 17.542,17.875C19.104,16.311 21.227,15.431 23.437,15.431C25.648,15.431 27.771,16.311 29.333,17.875L29.583,18.125C31.588,20.086 34.601,20.632 37.167,19.5L37.5,19.5C40.014,18.423 41.656,15.943 41.667,13.208L41.667,12.5C41.667,7.928 45.428,4.167 50,4.167C54.572,4.167 58.333,7.928 58.333,12.5L58.333,12.875C58.344,15.61 59.986,18.089 62.5,19.167C65.066,20.299 68.078,19.753 70.083,17.792L70.333,17.542C71.896,15.977 74.018,15.098 76.229,15.098C78.44,15.098 80.563,15.977 82.125,17.542C83.689,19.104 84.569,21.227 84.569,23.437C84.569,25.648 83.689,27.771 82.125,29.333L81.875,29.583C79.914,31.588 79.368,34.601 80.5,37.167L80.5,37.5C81.577,40.014 84.057,41.656 86.792,41.667L87.5,41.667C92.072,41.667 95.833,45.428 95.833,50C95.833,54.572 92.072,58.333 87.5,58.333L87.125,58.333C84.39,58.344 81.911,59.986 80.833,62.5Z',
        'M50,37.5C56.899,37.5 62.49,43.098 62.49,49.997C62.49,56.896 56.899,62.5 50,62.5C43.101,62.5 37.5,56.899 37.5,50C37.5,43.101 43.101,37.5 50,37.5Z',
      ],
      strokeWidth: '3',
    },
    checkUp: {
      viewBox: '1 0 20 23',
      paths: [
        'M22,11.08L22,12C21.997,17.484 17.484,21.994 12,21.994C6.514,21.994 2,17.48 2,11.994C2,6.508 6.514,1.994 12,1.994C13.402,1.994 14.789,2.289 16.07,2.86',
        'M22,4L12,14.01L9,11.01',
      ],
      strokeWidth: '0.7',
    },
    history: {
      viewBox: '1 0 20 23',
      paths: ['M11,19L2,12L11,5L11,19Z', 'M22,19L13,12L22,5L22,19Z'],
      strokeWidth: '0.7',
    },
    product: {
      viewBox: '1 0 20 23',
      paths: [
        'M12,2C17.519,2 21.988,6.481 21.988,12C21.988,17.519 17.519,22 12,22C6.481,22 2,17.519 2,12C2,6.481 6.481,2 12,2Z',
        'M14.31 8l5.74 9.94',
        'M9.69 8h11.468',
        'M7.38 12l5.74-9.94',
        'M9.69 16L3.948 6.056',
        'M14.31 16H2.847',
        'M16.62 12l-5.74 9.94',
      ],
      strokeWidth: '0.7',
    },
    user: {
      viewBox: '1 0 20 23',
      paths: [
        'M20,21L20,19C20,16.806 18.194,15 16,15L8,15C5.806,15 4,16.806 4,19L4,21',
        'M12,3C14.208,3 16.003,4.791 16.003,6.999C16.003,9.206 14.208,11 12,11C9.792,11 8,9.208 8,7C8,4.792 9.792,3 12,3Z',
      ],
      strokeWidth: '0.7',
    },
  }

  return (
    <div className='category-logo'>
      {!hidden && (
        <React.Fragment>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            height='50px'
            width='100%'
            viewBox={content[type]['viewBox']}
            id='svg'
          >
            {content[type]['paths'].map((e, i) => (
              <motion.path
                d={e}
                fill='transparent'
                strokeWidth={content[type]['strokeWidth']}
                stroke='#f29400'
                strokeLinecap='round'
                initial='start'
                animate='end'
                key={i}
                variants={i % 2 === 0 ? strokeTwoVariants : strokeOneVariants}
                transition={{
                  duration: 2,
                }}
              />
            ))}
          </svg>
          <motion.h3
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            key={5000}
          >
            {headline && (
              <React.Fragment>
                {headline} <br />
              </React.Fragment>
            )}
            {name && (
              <React.Fragment>
                {name} <br />
                {brand && brand} {set && <span>(Set {set})</span>}
              </React.Fragment>
            )}
            {date && <span>{date}</span>}
          </motion.h3>
        </React.Fragment>
      )}
    </div>
  )
}

export default CategoryLogo
