import fetchItems from './fetchItems'

const login = async (email, password) => {
  const requestBody = {
    query: `
      query Login($email: String!, $password: String!){
        login(email: $email, password: $password) {
          exp
          isAdmin
        }
      }
      `,
    variables: {
      email: email,
      password: password,
    },
  }

  return fetchItems(requestBody)
}

const signUp = async ({
  email,
  firstName,
  lastName,
  address,
  postalCode,
  city,
  country,
  phone,
  password,
}) => {
  const requestBody = {
    query: `
    mutation AddUser($email: String!, $password: String!, $firstName: String!, $lastName: String!, $address: String!, $postalCode: String!, $city: String!, $country: String!, $phone: String!) {
      addUser(userInput: {email: $email, password: $password, firstName: $firstName, lastName: $lastName, address: $address, postalCode: $postalCode, city: $city, country: $country, phone: $phone}) {
        exp
        isAdmin
      }
    }
    `,
    variables: {
      email: email.value,
      firstName: firstName.value,
      lastName: lastName.value,
      address: address.value,
      postalCode: postalCode.value,
      city: city.value,
      country: country.value,
      phone: phone.value,
      password: password.value,
    },
  }

  return fetchItems(requestBody)
}

const forgotPassword = async (email) => {
  const requestBody = {
    query: `
    query ForgotPassword($email: String!) {
      forgotPassword(email: $email) 
    }
    `,
    variables: {
      email: email,
    },
  }

  return fetchItems(requestBody)
}

const resetPassword = async (password, token) => {
  const requestBody = {
    query: `
            mutation ResetPassword($password: String!, $token: String!) {
              editPassword( newPassword: $password, forgotToken: $token) {
                  exp
                  isAdmin
              }
            }
            
        `,
    variables: {
      password: password,
      token: token,
    },
  }

  return fetchItems(requestBody)
}

const logoutCookies = async () => {
  const requestBody = {
    query: `
      query {
         logout {
          exp
          isAdmin
        }                
       }
    `,
  }

  return fetchItems(requestBody)
}

export { login, signUp, forgotPassword, resetPassword, logoutCookies }
