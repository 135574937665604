import React, { useContext } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import { motion } from 'framer-motion'
import { LogOut, MessageCircle, RotateCcw } from 'react-feather'

import AuthContext from '../../../context/context'

import './NavBack.scss'

const MotionLink = motion(NavLink)

const variants = {
  hidden: {
    y: -30,
    opacity: 0,
  },
  show: {
    y: 0,
    opacity: 1,
  },
}

// url, prompt, text

function NavBack({ prompt, text }) {
  const context = useContext(AuthContext)

  const history = useHistory()

  const handleClick = () => {
    if (prompt === 'back') {
      history.goBack()
    } else {
      context.logout()
    }
  }

  if (prompt) {
    // onClick check if Prompt = "back" (Dann URL = / ) oder Prompt = "logout" (Handling on top!)
    return (
      <React.Fragment>
        <motion.button
          variants={variants}
          className='nav-back-link'
          onClick={handleClick}
        >
          <div>{prompt === 'back' ? <RotateCcw /> : <LogOut />}</div>
          <p>{text}</p>
        </motion.button>
      </React.Fragment>
    )
  }
  return (
    <MotionLink variants={variants} className='nav-back-link' to='/kontakt'>
      <div>
        <MessageCircle />
      </div>
      <p>{text}</p>
    </MotionLink>
  )
}

export default NavBack
